* {
  scrollbar-width: thin;
  font-family: 'Lato', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input[type='button'] {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
}

.modal-header .close,
.MuiIconButton-sizeSmall.MuiIconButton-edgeEnd,
.MuiButtonBase-root {
  outline: none;
}

/* .MuiCardContent-root:last-child {
  padding-bottom: 15px !important;
  padding-top: 5px;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  margin-left: 100px;
  margin-top: 100px;
  text-align: left;
}

ul {
  list-style: none;
}

#sms .modal-content {
  width: 70% !important;
  margin: 0 auto;
}
#web .modal-content {
  width: 120% !important;
  margin: 0 auto;
  margin-left: -80px;
  z-index: 10000;
}
#user .modal-content {
  min-width: 110% !important;
  margin: 0 auto;
}
#disp .modal-content {
  width: 60% !important;
  margin: 0 auto;
  margin-top: 170px;
  z-index: 999;
}

#bulk .modal-content {
  width: 70% !important;
  margin: 0 auto;
  margin-top: 170px;
  height: 70vh;
  z-index: -1;
}

#fundWaya .modal-content {
  width: 60% !important;
  margin: 0 auto;
  margin-top: 170px;
}

.MuiList-padding {
  padding: 0;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  padding: 0;
}

.rec.rec-arrow {
  color: red;
  background-color: white;
}
/* [type=button]:not(:disabled),  button:not(:disabled){
  position : absolute;
  z-index: 1000;
 
} */

.sc-eCApnc.jCKguF.rec.rec-carousel {
  position: relative;
}

.sc-bdnxRM.cGKbZh.rec.rec-arrow.rec.rec-arrow-left {
  left: -22px;
  position: absolute;
  z-index: 1000;
}

.sc-bdnxRM.cGKbZh.rec.rec-arrow.rec.rec-arrow-right {
  position: absolute;

  right: -17px;
}

#tran .modal-content {
  width: 50% !important;
  margin: 0 auto;
  border-radius: 10px;
  /* height: 50px; */
  margin-top: 100px;
}
#fund .modal-content {
  width: 70% !important;
  margin: 0 auto;
  border-radius: 10px;
  height: 10% !important;
  margin-top: 100px;
}

#textf #outlined-select-currency-native {
  margin-bottom: -10px !important;
}

#textf #text2 {
  margin-bottom: 12px !important;
}

.page404 {
  display: none;
}

@media screen and (max-width: 1280px) {
  .screen {
    /* display: none; */
  }
  .page404 {
    display: block;
  }
}

/* Colors  */
.custom-bg-color {
  background-color: #ff4400 !important;
  /* background-color: #ff6634 !important; */
}

.custom-color {
  color: #ff4400 !important;
}

.add-cursor {
  cursor: pointer;
}
