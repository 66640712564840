/* Input style */

.inputbox-with-one-input,
.inputbox-with-one-input2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.inputbox-with-one-input,
.inputbox-with-one-input2 label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0d0d0d;
}

.inputbox-with-one-input input,
select {
  border: none !important;
  font-size: 16px;
  line-height: 25px;
  // color: #b6b3b3 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  padding-bottom: 6px;
  border-radius: 0 !important;
}

.inputbox-with-one-input2 input,
select {
  font-size: 16px;
  line-height: 25px;
  // color: #b6b3b3 !important;
  border: 1px solid #0d0d0d !important;
  padding-bottom: 6px;
  border-radius: 0 !important;
}

.inputbox-with-one-input select,
input {
  padding-left: 0 !important;
}

*:focus {
  outline: none;
}
.status {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.pause{
  width: 86px;
  height: 18px;
  background: #FFEEED;
  border: 1px solid #F44336;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #F44336;
  padding: 2px;
}

.play{
  width: 86px;
  height: 18px;
  padding: 2px;
  background: #DDFFEB;
  border: 1px solid #27AE60;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #27AE60;
}
.reason{
  width: 86px;
  height: 18px;
  padding: 2px;
  background: #DDFFEB;
  border: 1px solid #2783ae;
  box-sizing: border-box;
  border-radius: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #2783ae;
}
.stat-card{
  height: 72px;
  background: #FBFBFB;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  .title{
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #1C1C1C;
    margin-bottom: 5px;
  }
  .count{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #1C1C1C;
  }
  .date-box{
    width: 30px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    text-align: center;
  }
}
// .new-form input {
//   border: #0000 !important;
// }
